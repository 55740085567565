.MainContent {
  display: none;
}

.HomeKitImg {
  height: 55px;
  margin-right: 16px;
}

.InitialDesc {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.KitDescription {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-top: 16px;
}

.BuyContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Price {
  font-family: ModernEra;
  font-size: 33px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-left: 16px;
}

.BuyBtn {
  font-family: ModernEra;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
  border: 0;
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
  margin-left: 24px;
  outline: 0;
}

@media (min-width: 769px) {
  .MainContent {
    display: flex;
  }
}
