.MainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 48px 0px 0px 0px;
}

.InitialMsg {
  font-family: ModernEra;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.CenterListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}

.CenterCard {
  flex: 0 290px;
  display: flex;
  max-width: 290px;
}

.FooterNote {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-top: 24px;
  padding: 0 32px;
}

.Loader {
  margin: 0 auto;
  align-self: center;
}

@media (min-width: 360px) {
  .CenterCard {
    flex: 0 352px;
    max-width: 352px;
  }
}

@media (min-width: 769px) {
  .InitialMsg {
    align-self: flex-start;
  }

  .CenterCard {
    margin-bottom: 0px;
  }

  .PairCard {
    margin-top: 48px;
  }

  .FooterNote {
    margin-top: 48px;
  }
}

@media (min-width: 851px) {
  .CenterListContainer {
    justify-content: space-between;
  }
}
