.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 332px;
  border-color: aqua;
}

@media(max-width: 350px){
  .Wrapper {
    max-width: 280px;
  }
}

@media(min-width: 700px){
  .Wrapper {
    max-width: 650px;
  }
}

@media(min-width: 850px){
  .Wrapper {
    max-width: 800px;
  }
}

