.MainContainer {
  margin-bottom: 56px;
  margin-top: 56px;
}

.News1 {
  height: 21.1px;
}

.News2 {
  height: 22.6px;
}

.News3 {
  height: 14.5px;
}

@media (min-width: 769px) {
  .News1 {
    height: 45px;
  }
  
  .News2 {
    height: 32px;
  }
  
  .News3 {
    height: 30.9px;
  }
}