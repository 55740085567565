.FooterContainer {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 87px;
  margin-top: 56px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Contact {
  margin-left: 32px;
}

.WhatsContainer {
  font-family: HelveticaNeue;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SocialNetworks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 32px;
  width: 28%;
}

.Icons {
  background-color: #000000;
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.WhatsappIcon {
  background-image: url('../../assets/svg/whatsapp.svg');
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 8px;
}

.FacebookIcon {
  background-image: url('../../assets/svg/facebook.svg');
  background-repeat: no-repeat;
  height: 22px;
  width: 11.8px;
  display: block;
}

.InstagramIcon {
  background-image: url('../../assets/svg/instagram.svg');
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  display: block;
}

.YoutubeIcon {
  background-image: url('../../assets/svg/youtube.svg');
  background-repeat: no-repeat;
  width: 23.5px;
  height: 16.5px;
  display: block;
}

@media (min-width: 769px) {
  .SocialNetworks {
    width: 12%;
  }
}