.ContainerDates {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.ContainerLoader {
  margin-top: 100px;
}

.CenterContainer {
  margin-top: 42px;
  display: flex;
  width: 100%;
  max-width: 240px;
  padding: 16px 27px 11px;
  border-radius: 10px;
  border: solid 1.3px #003fff;
  background-color: #ffffff;
  outline: none;
}

.Select {
  margin: 40px 0 0;
  width: 100%;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}


.AddressContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Address {
  margin: 4px 0 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.Center {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
}

.Icon {
  height: 40px;
  width: 40px;
}

.DatesContainer {
  margin-top: 40px;
  width: 100%;
}

.Label {
  text-transform: capitalize;
}

.AllDates {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ImageArrow {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
}

.Left {
  margin-right: 1px;
}

.Right {
  margin-left: 1px;
}

.ContainerDesktop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 48px;
  max-width: 557px;
  margin-top: 5px;
}

.ContainerMobile {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
}

.Item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Item > p{
  margin: 0 0 12px;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Time {
  margin-top: 20px;
  padding: 8px 36px;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 4px 8px 8px 0 #d2ddff;
  background-color: #ffffff;
  outline: none;
  cursor: pointer;
}


.ContainerTimes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 48px;
  max-width: 557px;
}

.ImageArrow img{
  width: 7.3px;
  height: 12.7px;
  outline: none;
}

.ImageArrow {
  width: 10.3px;
  height: 10.3px;
  outline: none;
}

.Back {
  display: flex;
  position: absolute;
  left: 25px;
  outline: none;
  cursor: pointer;
  width: 200px;
}

.Back > p {
  margin: 0 0 0 6px;
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #0752f9;
}

.TitleName {
  margin: 0;
  font-family: QuincyCF;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.EmptyDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: 396px;
  background-color: #f7f9ff;
}

.EmptyDate > p {
  margin: 25px 0 0;
  font-family: QuincyCF;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  max-width: 222px;
}

.EmptyDateDesk > p {
  margin: 0 5px;
  font-family: QuincyCF;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  max-width: 222px;
}

.EmptyDate > img {
  margin-top: 58px;
}

.EmptyDateDesk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 396px;
  background-color: #f7f9ff;
}

@media(max-width: 350px){  
  .EmptyDate {
    width: 260px;
  }

  .CenterContainer {
    padding: 16px 20px 11px;
  }
}


@media(min-width: 700px){

  .CenterContainer {
    max-width: 508px;
  }

  .ContainerDates {
    margin-bottom: 83px;
  }
}
