.CheckoutWrapperContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 88vh;
}
.CheckoutContainer {
  display: flex;
  flex-direction: column;
  font-family: 'ModernEra';
  flex-grow: 1;
  margin-bottom: 1em;
}



.AppointmentContainer {
  background-color: #fceed2;
  display: flex;
  flex-direction: row;
}
.AppointmentIcon {
  width: 30%;
  position: relative;
}

.AppointmentText {
  width: 70%;
  margin-right: 14px;
}

.AppointmentText > h5 {
  font-family: 'ModernEra';
  font-weight: 800;
  font-size: 1em;
  margin-top: 18px;
  margin-bottom: 0em;
}

.AppointmentText > p {
  font-family: 'ModernEra';
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 16px;
}

.IvaContainer {
  text-align: end;
  margin-right: 32px;
}

.ListContainer > ul {
  margin-top: 0px;
  padding: 0px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.ListContainer > ul li {
  display: inline;
  font-size: 0.78em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.warningImageContainer {
  margin-left: 1.5em;
  margin-right: 1.2em;
  justify-content: center;
  position: absolute;
  top: 25%;
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
}
img.warningImage {
  margin-left: 20%;
  width: 80%;
}
img.checkImage {
  width: 0.9em;
  transform: translateY(15%);
}

.Row {
  display: flex;
  flex-direction: row;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  justify-content: space-between;

}

.Col {
  width: 100%;
}

.Col2Left {
  width: 30%;
}
.Col2Right {
  width: 30%;
}

.CardInput{
  border-radius: 6px;
  border: solid 1px #707070;
  background-color: #ffffff;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 6px;
  padding-right: 6px;
}

.CheckoutFormContainer{
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.CheckoutFormContainer  label {
  font-size: 0.9em;
}

.FooterIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: white;
  margin-left: 20%;
  margin-right: 20%;
}


.ImageFooterContainer {
  width: 25%;
}
.ImageFooterContainer > img {
  width: 100%;
}

.PayButtonContainer {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: flex;
  outline: 0;
}

.PayButtonContainer > button {
  font-family: ModernEra;
  font-size: 16px;
  margin-left: 10%;
  margin-right: 10%;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  /* btn */
  cursor: pointer;
  background-color: #0752f9;
  border: 0;
  border-radius: 10px;
  padding: 14px 40px;
  margin-top: 16px;
  margin-bottom: 18px;
  outline: 0;
}


.ErrorComponent {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fae3e0;
  margin-bottom: 1.5em;
  padding-bottom: 1em;
}

.ErrorIcon {
  width: 30%;
  margin-left: 2em;
  position: relative;
}

.ErrorIconContainer {
  justify-content: center;
  position: absolute;
  top: 25%;
  -ms-transform: translateY(-5%);
  transform: translateY(-5%);
}

.ErrorIconContainer img {
  width: 60%;
}
.ErrorText {
  width: 70%;
  margin-right: 14px;
}

.ErrorText > h5 {
  font-family: ModernEra;
  font-size: 1em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-top: 20px;
  margin-bottom: 12px;
}

.ErrorText > p {
  font-family: ModernEra;
  font-size: 0.9em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin: 0;
}


.ContactCheckoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.ContactCheckoutContainer h5 {
  font-family: ModernEra;
  font-size: 1em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.WhatsAppcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.whatsappIcon{
  margin-right: 0.1em;
}

.whatsappIcon img{
  width: 40%;
}
.contactLink {
  margin-left: 0.1em;
  margin-right: 10%;
}
.WhatsAppcontainer a {
  font-family: ModernEra;
  font-size: 1em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #009d25;
}

.noDisplay {
  display: none;
}

.ContainerLoader {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 25%;
}

.Center  {
  margin-top: 8%;
}

.AnimationContainer {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background: #0752f9;
  outline: 0;
}

.AnimationContainer::after {
  animation: shine 3s ease-in-out  infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 170%;
  height: 400%;
  opacity: 0;
  transform: rotate(20deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -210%;
    left: -40%;
    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 1s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -210%;
    left: -40%;
    transition-property: left, top, opacity;
  }
}

.ErrorAppointmentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  height: 500px;
  background-color: #f7f9ff;
  font-family: ModernEra;
  font-size: 1em;
}
@media (min-width: 400px) and (max-width: 720px) {
  .CheckoutContainer {
    font-size: 115%;
  }
  .CheckoutWrapperContainer {
    min-height: 86vh;
  }
  
  .ListContainer > ul li {
    font-size: 0.9em !important;
  }
}

@media (min-width: 800px) {
  .CheckoutWrapperContainer {
    justify-content: center;
    min-height: 90vh;
  }
  .ErrorAppointmentContainer {
    width: 800px;
    font-size: 130%;
    justify-content: center;
    margin-left: calc((100% - 800px)/2);
    margin-right: calc((100% - 800px)/2);
    margin-bottom: 4em;
  }
  .CheckoutContainer {
    width: 800px;
    font-size: 130%;
    justify-content: center;
    margin-left: calc((100% - 800px)/2);
    margin-right: calc((100% - 800px)/2);
    margin-bottom: 4em;
  }
  .ListContainer {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }
  .ListContainer > ul li {
    font-size: 1em !important;
  }
  .CheckoutFormContainer{
    margin-left: 3em !important;
    margin-right: 3em !important;
  }

  .FooterIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 20px;
    background-color: white;
    margin-left: 20%;
    margin-right: 20%;
  }
  
  
  .ImageFooterContainer {
    width: 15%;
  }
  .Center  {
    margin-top: 5%;
  }
  .WhatsAppcontainer {
    margin-left: 40px;
  }
}