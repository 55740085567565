.MainContainer {
  margin-top: 52px;
  width: 100%;
}

.StepsContainer {
  background-color: #fceed2;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 45px 32px;
}

.Title {
  font-family: QuincyCF;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Row {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 39px;
}

.TextContainer {
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  flex: 3 1;
}

.ImgContainer {
  flex: 1 1;
}

.CameraImage, .RefundImage {
  height: 39.4px;
}

.SaveTimeImage {
  height: 47.3px;
}

.SendButton {
  border-radius: 10px;
  box-shadow: 0 3px 12px 0 rgba(7, 82, 249, 0.35);
  background-color: #0752f9;
  border: 0px;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 48px;
  padding: 16px 20%;
  cursor: pointer;
  outline: 0;
}

.ButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(min-width: 700px){
  .StepsContainer {
    padding: 45px 20%;
  }
}