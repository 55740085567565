.TabletContainer{
    display: none;
}

.CarouselTablet {
    display: flex;
    height: 100%;
    align-self: center;
    margin: 0 80px;
    width: 100%;
    padding-bottom: 5px;
    overflow: hidden;
    position: relative;
}

.SlideshowContainerTable {
    position: relative;
    display: flex;
    left: 0;
}

.SliderMoveTablet {
    width: 150%;
    display: flex;
}

.StepContainerImagesTable{
    width: 100%;
    display: flex;
    padding: 0 2% 0 2%;
}
.CarouselImage{
    align-self: center;
    width: 100%;
}

.PhotoTablet{
    flex-direction: column;
    width: 30%;
    display: inline-flex;
    margin: 0 18px 8px 18px;
    align-self: stretch;
    min-height: 219px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}

.CarouselHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 52px;
}

.HeaderTitle {
    margin: 28px 0 0;
    font-family: QuincyCF;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #1e1e1e;
}

.SliderActive {
    border: none;
    width: 10px;
    height: 10px;
    background-color: #99bffc;
    border-radius: 5px;
    opacity: .5;
    z-index: 100;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
}

.SliderBootOut {
    align-self: center;
    bottom: 5px;
    z-index: 1;
    height: 20px;
    text-align: center;
}

.SliderBottom {
    border: none;
    transition: opacity .25s;
    width: 6px;
    height: 6px;
    background-color: #d8d8d8;
    border-radius: 3px;
    opacity: .5;
    z-index: 100;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
}

.Wrapper {
    overflow: hidden;
    margin: 0 auto;
    /*width: calc(100% - 78px);  margin_total: 57 margin_item: 18   2*(57-18) = 78*/
}

.Circle {
    width: 70px;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.3);
    border-color:  rgba(248, 244, 244, 0.3);
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    font-size: 2.5rem;
    line-height: 4rem;
    font-size: 26px;
    position: absolute;
    margin-top: 8%;
    z-index: 100;
    outline: none;
}
 
.Next {
    right: 20px;
    background-image: url('../../../assets/svg/next.svg');    
}

.Prev {
    background-image: url('../../../assets/svg/before.svg');    
}

@media (min-width: 700px) {
    .TabletContainer {
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-self: center;
    }

    .CarouselTablet {
        padding-left: 20px;
    }
} 

@media (min-width: 1000px) {
    .Next {
        right: 90px;
    }
    
    .Prev {
        left: 90px;
    }

    .Circle {
        margin-top: 15%;
    }

    .CarouselTablet {
        padding-left: 0;
    }
} 