.CenterContainer {
  display: flex;
  flex-direction: column;
  padding: 28px;
  width: 100%;
  max-width: 294px;
  border-radius: 10px;
  border: solid 1.3px #003fff;
  background-color: #ffffff;
  outline: none;
}

.Item {
  display: flex;
}

.ItemSecond {
  display: flex;
  margin-top: 24px;
}

.Icon {
  width: 20.8px;
}

.BlueIcon {
  filter: brightness(0) saturate(100%) invert(12%) sepia(86%) saturate(6451%) hue-rotate(231deg) brightness(100%) contrast(105%);
}

.InfoDate {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Name {
  font-family: ModernEraBold;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Center {
  justify-content: center !important;
}

.Center, .Dates {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
}

.Address {
  margin: 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

@media(max-width: 350px){
  .CenterContainer {
    max-width: 224px;
  }
}