.MainContainer {
  background-color: #edf8fb;
  margin-top: 59px;
  padding: 40px 32px;
}

.Title {
  font-family: QuincyCF;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  margin-bottom: 32px;
}

.Row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FirstStep, .SecondStep, .ThirdStep {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.SecondStep, .ThirdStep {
  margin-top: 40px;
}

.DrImage {
  height: 47.3px;
}

.FirstText, .SecondText, .ThirdText {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.SmileImage {
  height: 28.3px;
}

.ApolloImage {
  height: 47.5px;
}

@media (min-width: 769px) {
  .MainContainer {
    /* margin-left: 10%;
    margin-right: 10%; */
    border-radius: 20px;
  }

  .Row {
    flex-direction: row;
    align-items: baseline;
  }

  .FirstStep, .SecondStep, .ThirdStep {
    flex-direction: column;
    margin: 0 10px;
  }

  .SecondStep, .ThirdStep {
    margin-top: 0;
  }

  .FirstText, .SecondText, .ThirdText {
    margin-top: 24px;
  }
}