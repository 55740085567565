.MainContent {
  padding-right: 32px;
  margin-top: 32px;
}

.Title {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.MaskImage {
  height: 44px;
}

.Message {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-top: 16px;
}

.Button {
  margin-top: 24px;
}

.MoreBtn {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
  border: 0;
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  outline: 0;
}
