.WelcomeContainer{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
}

.ImageSide{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ImageSide .ImageWelcome{
    width: 200px;
    height: 200px;
}

.ImageWelcome img{
    width: 100%;
}

.MessageSide{
    width: 100%;
}

.Message{
    width: 100%;
    background-color: #d0f7e7;
}

.MessageContent{
    padding: 20px;
    /* height: 200px; */
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
}

.MessageIcon{
    width: 56px;
    height: 56px;
}

.MessageIcon img{
    width: 100%;
}

.MessageText{
    padding-left: 20px;
}

.MessageTitle{
    font-family: QuincyCF;
    color: #1e1e1e;
    font-size: 23px;
    margin-top: 0;
}

.MessageParagraph{
    font-family: ModernEra;
    color: #1e1e1e;
    font-size: 18px;
}



@media (min-width: 1000px){
    .WelcomeContainer{
        /* width: 80%; */
        flex-flow: row;
        max-width: 900px;
    }

    .ImageSide{
        width: 30%;
    }

    .MessageSide{
        width: 70%;
    }

    .Message{
        border-radius: 20px;
    }

    .MessageContent{
        align-items: center;
        justify-content: center;
    }

    .MessageIconContainer{
        width: 30%;
        display: flex;
        justify-content: center;
    }
}
