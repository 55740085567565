.MainContainer {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-left: 32px;
  margin-right: 32px;
  align-items: center;
}

.Heading {
  display: flex;
  flex-direction: row;
}

.HomeKitImg {
  height: 55px;
}

.KitContent {
  margin-left: 19px;
  align-items: flex-start;
}

.InitialDesc {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.BuyContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 18px;
}

.Price {
  font-family: ModernEra;
  font-size: 28px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.BuyBtn {
  font-family: ModernEra;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
  border: 0;
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
}

.InfoBtn {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  margin-top: 18px;
  cursor: pointer;
}

.ArrowIcon {
  height: 6.5px;
  margin-left: 6px;
}

.KitDescription {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-top: 32px;
}

@media (min-width: 769px) {
  .MainContainer {
    margin-left: 0;
    margin-right: 0;
  }

  .MobileContent {
    display: none;
  }
}