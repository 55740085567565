body{
    margin: 0;
}

@font-face {
    font-family: 'ModernEra';
    src: url("./assets/fonts/ModerEra/ModernEraRegular.ttf");
}

@font-face {
    font-family: 'ModernEraMono';
    src: url("./assets/fonts/ModerEra/ModernEraMonoRegular.ttf");
}

@font-face {
    font-family: 'ModernEraMonoBold';
    src: url("./assets/fonts/ModerEra/ModernEraMonoBold.ttf");
}

@font-face {
    font-family: 'ModernEraBold';
    src: url("./assets/fonts/ModerEra/ModernEraBold.ttf");
}

@font-face {
    font-family: 'CooperBT';
    src: url("./assets/fonts/Cooper/CooperLtBT-Regular.ttf");
}

@font-face {
    font-family: 'QuincyCFBold';
    src: url("./assets/fonts/QuincyCF/QuincyCF-Bold.woff");
}

@font-face {
    font-family: 'QuincyCFMedium';
    src: url("./assets/fonts/QuincyCF/QuincyCF-Medium.woff");
}

@font-face {
    font-family: 'QuincyCF';
    src: url("./assets/fonts/QuincyCF/QuincyCF-Regular.woff");
}

@font-face {
    font-family: 'QuincyCFItalic';
    src: url("./assets/fonts/QuincyCF/QuincyCF-RegularItalic.woff");
}