.MainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ThanksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Cosmonaut {
  width: 166px;
}

.ThankyouContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}

.Thankyou {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: #d0f7e7;
  padding: 32px 24px;
  width: 100%;
}

.CheckIcon {
  width: 56px;
  margin-right: 24px;
}

.ThanksText {
  font-family: QuincyCF;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ResumeLink {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  align-items: center;
  margin-top: 29px;
}

.ContainerLinkCalendar {
  display: flex;
  margin-top: 37.8px;
}

.ImageCalendar {
  width: 9.8px;
  margin-right: 8.4px;
}


.LinkCalendar {
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
}

.Recommendation {
  display: flex;
  border-radius: 10px;
  background-color: #fceed2;
  margin-top: 43px;
  max-width: 350px;
  margin-left: 24px;
  margin-right: 24px;
}

.ContainerRec {
  display: flex;
  flex-direction: column;
  margin: 16px 25px;
}

.TitleRecommendation {
  font-family: ModernEraBold;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin: 0;
}

.ItemRec {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin: 16px 0 0;
}

.MapImagesContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 40px;
  width: 100%;
}

.MapImage {
  width: 100%;
  border-radius: 10px;
  max-width: 350px;
  cursor: pointer;
  outline: none;
}

.MapCont {
  cursor: pointer;
  outline: none;
  align-self: center;
}

.SeeMaps {
  display: flex;
  cursor: pointer;
  outline: none;
  margin-top: 33px;
  align-self: center;
}

.SeeMaps > p {
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
  text-decoration: underline
}

.IconMap {
  margin-right: 12.3px;
}

@media (min-width: 350px) {
  .Thankyou {
    padding: 32px 32px;
  }

  .Recommendation {
    /* max-width: 280px; */
    margin-left: 32px;
    /* margin-right: 32px; */
  }
}

@media(min-width: 650px){
  .Thankyou {
    border-radius: 10px;
  }
}

@media(min-width: 700px){
  .Ok {
    width: 86px;
    margin-right: 50px;
  }

  .ResumeLink {
    margin-top: 34px;
  }

  .Section {
    flex-direction: row;
    max-width: 735px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .Recommendation {
    margin-top: 32px;
    margin-right: 0;
  }

  .ThanksContainer {
    margin: 33px 0px;
  }

  .MapImage {
    max-width: 100%;
  }

  .MainContainer {
    max-width: 650px;
  }

  .MapCont {
    align-self: auto;
  }
}

@media (min-width: 769px) {
  .Thankyou {
    padding: 48px;
  }

  .MainContainer {
    margin-bottom: 61px;
  }
}

@media(min-width: 850px){
  .MainContainer {
    max-width: 800px;
  }
}