.CenterCard, .CardNoShadow {
  border-radius: 19px;
  box-shadow: 4px 8px 8px 0 #d2ddff;
  background-color: #ffffff;
  margin-bottom: 24px;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  max-width: 290px;
}

.CardNoShadow {
  box-shadow: none !important;
  border: 1px solid black;
}

.LeftCard, .LeftCardNoShadow {
  display: flex;
  flex-direction: row;
  padding: 16px 10px 6px;
  flex: 2 1;
}

.LeftCardNoShadow {
  border-right: 1px solid black;
}

.LeftImg {
  margin-right: 10px;
}

.LeftText {
  justify-content: space-between !important;
}

.RightCard {
  height: inherit;
  background-color: #fae3e0;
  border-radius: 0px 19px 19px 0px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.OfficeHrs > p, .OfficeDate > p {
  margin: 0
}

.TowerImg {
  height: 32px;
}

.DefaultImg {
  height: 29px;
}

.LocationTitle {
  font-family: ModernEra;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #0752f9;
}

.Street, .City {
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.Street {
  margin-top: 6px;
}

.OfficeHrs, .WeekBookings {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.OfficeDate {
  font-family: ModernEraBold;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
}

.OfficeHrs, .OfficeDate {
  margin-top: 8px;
}

.WeekBookings {
  flex-wrap: nowrap !important;
  margin-top: 10px;
}

.FlameIcon {
  height: 11px;
}

.WeekBkgsText {
  margin-left: 8px;
}

.PriceContainer {
  background-color: #fae3e0;
  display: flex;
  flex-direction: column;
  border-radius: 0px 19px 19px 0px;
  /* align-items: center; */
}

.DiscountPrice, .Price {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Price {
  text-decoration: line-through;
}

.SelectBtn {
  border-radius: 10px;
  box-shadow: 4px 4px 4px 0 rgba(0, 63, 255, 0.24);
  background-color: #ea1c12;
  border: 0;
  cursor: pointer;
  /* font */
  font-family: ModernEra;
  font-size: 13px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 16px 10px;
  margin-top: 16px;
}

@media (min-width: 360px) {
  .CenterCard, .CardNoShadow {
    max-width: 352px;
  }

  .DiscountPrice {
    font-size: 19px;
  }

  .SelectBtn {
    font-size: 16px;
  }
}

@media (min-width: 769px) {
  .CenterCard {
    margin-bottom: 0px;
  }
}

